






























































import Vue from 'vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'

export default Vue.extend({
  name: 'replay-settings',
  components: {
    'ui-toggle-switch': ToggleSwitch
  },
  props: {
    disabled: Boolean,
    displayAvgOption: {
      type: Boolean,
      default: false
    },
    isReplayEnabled: Boolean,
    isReplayWeatherStationAvgTempEnabled: Boolean,
    isReplayWeatherStationEnabled: Boolean,
    isReplayForecastEnabled: Boolean,
    isReplayInformation: Boolean,
    isReplayDrawing: Boolean
  }
})

