





















































import Vue from 'vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  name: 'broadcast-dialog',
  components: {
    'ui-loader': Loader,
    'ui-button': Button,
    'ui-dialog': Dialog
  },
  props: {
    drawing: {
      type: Object,
      default: null
    },
    broadcasting: {
      type: Boolean,
      default: false
    },
    broadcasted: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, undefined],
      default: undefined
    }
  }
})
