import apiService from '@/services/api'
import { createStore } from 'vue-reactive-store'
import { ConfigData } from './definitions'

/**
 * This store is about configuration.
 * The configuration is retrieved from the DRF backend.
 * We init the config at the boostrap of the application,
 * or it's given directly by the index.html generated by the backend.
 */
export let defaultConfig: ConfigData = {

  DEFAULT_HOME_PAGE: 'forecast',

  SITE_TITLE: 'Weather F1',
  SITE_NAME: 'MFS Web platform',
  SITE_DESCRIPTION: 'Waiting for server\'s configuration...',
  SITE_CONTACT: 'No mail...',

  // COMMIT: COMMIT, // given by webpack

  THEME: 'light-blue',

  LOGOS_1_SRC: '/logos/MF_logo.svg',
  LOGOS_1_MOBILE_SRC: '/logos/MF_logo_blue.svg',
  LOGOS_1_TITLE: 'Météo France',
  LOGOS_2_SRC: '',
  LOGOS_2_MOBILE_SRC: '',
  LOGOS_2_TITLE: '',
  LOGOS_3_SRC: '',
  LOGOS_3_MOBILE_SRC: '',
  LOGOS_3_TITLE: '',

  WEATHERSTATION_PERIOD_DEFAULT: 2,

  REFRESH_FORECAST_RAIN: 30000, // millisecond
  REFRESH_FORECAST_DAILY: 30000, // millisecond
  REFRESH_FORECAST_HOURLY: 30000, // millisecond
  REFRESH_WEATHER_DATA: 30000, // millisecond
  REFRESH_RADAR: 30000, // millisecond
  REFRESH_TOKEN: 300000, // millisecond

  MAP_PLAYER_PERIOD: 1, // minutes
  MAP_PLAYER_INTERVAL: 60, // minutes
  MAP_PLAYER_FUTURE_INTERVAL: 15, // minutes

  MAP_TARGET_CIRCLE_INTERVAL: 2500,
  MAP_TARGET_CIRCLE_ITERATION: 42,
  MAP_RAINPATCH_TIME_INTERVAL: 5,

  MAP_DEFAULT_ZOOM: 12,
  MAP_DEFAULT_TRANSITION: 200,

  MAP_WMS_LAYER: 'reflectivity_range',
  MAP_WMS_DIM_PROCESS: 'meteofrancesport_mobile',

  // ARCHIVE_DEFAULT_DAY: FIRST,
  ARCHIVE_DEFAULT_HOUR: 9,
  ARCHIVE_DEFAULT_MINUTE: 0,
  ARCHIVE_BACKWARD_PERIOD_FORECAST_DAILY: 96, // hour
  ARCHIVE_BACKWARD_PERIOD_FORECAST_HOURLY: 96, // hour
  ARCHIVE_BACKWARD_PERIOD_FORECAST_RAIN: 96, // hour

  LIMIT_FORECAST_BYDAY_TEMPERATURE_MIN: 30,
  LIMIT_FORECAST_BYDAY_TEMPERATURE_MAX: 30,
  LIMIT_FORECAST_BYDAY_RAIN_CHANCE: ['40%', '80%', 'more than 80%'],
  LIMIT_FORECAST_BYDAY_WIND: ['30 to 40'],

  LIMIT_FORECAST_HOURLY_TEMPERATURE_AIR: 30,
  LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MIN: 30,
  LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MAX: 30,
  LIMIT_FORECAST_HOURLY_RAIN_CHANCE: ['40%', '80%', 'more than 80%'],
  LIMIT_FORECAST_HOURLY_WIND_AVERAGE: ['5 to 15', '10 to 20'],
  LIMIT_FORECAST_HOURLY_WIND_GUSTS: ['5 to 15', '10 to 20'],
  LIMIT_FORECAST_HOURLY_HEATINDEX: 40,
  LIMIT_FORECAST_HOURLY_HUMIDITY: 50,
  LIMIT_FORECAST_HOURLY_PRESSURE: 1000,

  LIMIT_WSDATA_TEMPERATURE_AIR: 30,
  LIMIT_WSDATA_TEMPERATURE_WBGT: 30,
  LIMIT_WSDATA_TEMPERATURE_TRACK: 30,
  LIMIT_WSDATA_WIND_AVERAGE: 2,
  LIMIT_WSDATA_WIND_GUSTS: 4,
  LIMIT_WSDATA_RAIN: 0.06,
  LIMIT_WSDATA_HUMIDITY: 30,
  LIMIT_WSDATA_PRESSURE: 1000,

  DISPLAY_FORECAST_BYDAY_PRESSURE: false,

  DISPLAY_FORECAST_HOURLY_SESSION: false,
  DISPLAY_FORECAST_HOURLY_PREVIOUS_NIGHT: true,

  DISPLAY_FORECAST_HOURLY_HUMIDITY: true,
  DISPLAY_FORECAST_HOURLY_HEATINDEX: true,
  DISPLAY_FORECAST_HOURLY_PRESSURE: true,
  DISPLAY_FORECAST_HOURLY_GUST: true,
  DISPLAY_FORECAST_HOURLY_TRACK_MIN_MAX: true,
  DISPLAY_FORECAST_HOURLY_CHANCE_OF_RAIN: true,
  DISPLAY_FORECAST_HOURLY_TRACK_WBGT: 'track',

  DISPLAY_NOWCASTING_WARNING_RADAR_SHORT_PERIOD: 5,
  DISPLAY_NOWCASTING_WARNING_RADAR_LONG_PERIOD: 15,
  DISPLAY_NOWCASTING_GROUND_STATION_TRACK_WBGT: 'track',
  DISPLAY_OBSERVATION_TRACK_WBGT: 'track',
  DISPLAY_DASHBOARD_WIDGET_GROUND_STATION_TRACK_WBGT: 'track',

  DISPLAY_FORECAST_HOURLY_OCCURRENCES: 12,

  DISPLAY_GUIDE_API: true,

  MIN_TIMELINE_MINUTES_RANGE: 30, // minimum selected range in minutes
  TICK_TIMELINE_INTERVAL: 4, // interval between each ticks in hours

  // Minimum number of minutes between two recorded times that should be
  // interpreted as a gap (i.e. lack of radar images).
  // Smaller gaps will be hidden from the timeline.
  DISPLAY_MESSAGE_LACK_RADAR_IMAGE_AFTER_PERIOD: 20,

  WEATHERSTATION_WBGT_THRESOLD_1: 28,
  WEATHERSTATION_WBGT_THRESOLD_2: 30,
  WEATHERSTATION_WBGT_THRESOLD_3: 32,

  WEATHERSTATION_HEAT_THRESOLD_1: 32,
  WEATHERSTATION_HEAT_THRESOLD_2: 40,
  WEATHERSTATION_HEAT_THRESOLD_3: 60
}

if (CONFIGURATION) {
  defaultConfig = {
    ...defaultConfig,
    ...CONFIGURATION
  }
}

const store = {
  name: 'config',
  state: {
    loading: false,
    data: {
      ...defaultConfig
    },
    error: null
  },
  computed: {
    logos (): {src: string; title: string; url: string}[] {
      const result = [];
      ['LOGOS_1_SRC', 'LOGOS_2_SRC', 'LOGOS_3_SRC'].forEach(key => {
        if (store.state.data[key]) {
          result.push({
            src: store.state.data[key],
            title: store.state.data[key.replace('_SRC', '_TITLE')],
            url: './assets' + store.state.data[key]
          })
        }
      })
      return result
    },
    logosMobile (): {src: string; title: string; url: string}[] {
      const result = [];
      ['LOGOS_1_MOBILE_SRC', 'LOGOS_2_MOBILE_SRC', 'LOGOS_3_MOBILE_SRC'].forEach(key => {
        if (store.state.data[key]) {
          result.push({
            src: store.state.data[key],
            title: store.state.data[key.replace('_MOBILE_SRC', '_TITLE')],
            url: './assets' + store.state.data[key]
          })
        }
      })
      return result
    }
  },
  actions: {
    /**
     * Fetch the config from the backend server
     */
    async fetchConfig () {
      store.state.loading = true
      try {
        const response = await apiService.getConfig()
        store.state.data = { ...response.data }
        document.title = store.state.data.SITE_TITLE
      } catch (e) {
        store.state.error = e
      }
      store.state.loading = false
    }
  }
}

export const vrsStoreConfig = createStore(store)
