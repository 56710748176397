/* eslint-disable no-console */
import autobahn from 'autobahn'
import preferences from './preferences'

export enum CrossbarDataType {
  RADAR = 'new_radar',
  RADAR_PERIODS = 'new_radar_periods',
  STATION_DATA ='new_stationdata',
  FORECAST_HOURLY ='new_forecast_hourly',
  FORECAST_DAILY ='new_forecast_daily',
  FORECAST_RAIN ='new_forecast_rain',
  INFO ='new_info',
  DRAWING ='new_drawing',
  MESSAGE ='new_message',
  AVERAGE_TEMPERATURE ='new_avgtemp',
  AIRPARIF_DATA ='new_airparif_data',
  EVENT_SESSION_OVER ='event_session_over'
}

class CrossbarService {
  private _enabled = false
  private _connection: autobahn.Connection

  /**
   * Listeners is an array of [CrossbarDataType => [listener1, listener2, ...]]
   */
  private _listeners: { [T in CrossbarDataType]: Array<Function> } = {
    [CrossbarDataType.FORECAST_DAILY]: [],
    [CrossbarDataType.STATION_DATA]: [],
    [CrossbarDataType.RADAR]: [],
    [CrossbarDataType.RADAR_PERIODS]: [],
    [CrossbarDataType.FORECAST_HOURLY]: [],
    [CrossbarDataType.FORECAST_RAIN]: [],
    [CrossbarDataType.INFO]: [],
    [CrossbarDataType.DRAWING]: [],
    [CrossbarDataType.MESSAGE]: [],
    [CrossbarDataType.AVERAGE_TEMPERATURE]: [],
    [CrossbarDataType.EVENT_SESSION_OVER]: [],
    [CrossbarDataType.AIRPARIF_DATA]: []
  }

  /**
   * Open the Crossbar Connection
   */
  open (forceCreation?: boolean) {
    if (!this._connection || forceCreation === true) {
      if (!preferences.token) {
        console.log('[CROSSBAR] Can\'t create socket without authent')
        return
      }
      console.log('[CROSSBAR] Creating new connection')
      this._connection = new autobahn.Connection({
        url: SITE_WS_URL,
        realm: 'realm1',
        authid: preferences.token
      })
      this._connection.onopen = (session: any) => {
        console.log(`[CROSSBAR] Connected to web socket for PubSub, id ${session._id}`)
        session.subscribe('mfs_notifications', this.triggerAction)
          .then(
            function () {
              console.log('[CROSSBAR] subscribed to topic "mfs_notifications"')
            },
            function (err) {
              console.error('[CROSSBAR] failed to subscribe to topic', err)
            }
          )
      }

      this._connection.onclose = function (reason, details) {
        console.log('[CROSSBAR] Connection closed / lost: ' + reason, details.message)
        return false
      }
    }

    // open it if it's not opened
    !this._connection.isOpen && this._connection.open()
  }

  /**
   * Close the Crossbar Connection if opened
   */
  close () {
    if (this._connection && this._connection.isOpen === true) {
      this._connection.close()
    }
  }

  restart () {
    console.log('[CROSSBAR] Restarting crossbar...')
    this.open()
  }

  /**
   * Enable crossbar's action to be triggered when a new notification is detected
   */
  enable = () => {
    if (this._enabled) return
    this._enabled = true
    console.log('[CROSSBAR] Service is now enabled.')

    // this.checkLastMessageReceived()
  }

  /**
   * Disable crossbar's action to be triggered when a new notification is detected
   */
  disable = () => {
    if (!this._enabled) return
    this._enabled = false
    console.log('[CROSSBAR] Service is now disabled.')
    // we stop the timer
    // clearTimeout(this._timeoutId)
    // console.log('[CROSSBAR] Timer is now disabled.')
  }

  on = (dataType: CrossbarDataType, listener: Function) => {
    this._listeners[dataType].push(listener)
  }

  off = (dataType: CrossbarDataType, listener: Function) => {
    this._listeners[dataType].splice(this._listeners[dataType].indexOf(listener), 1)
  }

  /**
   * Trigger an action
   * depending on the first argument of the crossbar message
   *
   * @param {Array<String>} args
   * Arguments of the crossbar message received
   */
  triggerAction = ([dataType, id]: [CrossbarDataType, number]) => {
    // continue only if crossbar is enabled
    if (!this._enabled) return
    console.log(`[CROSSBAR] Data type ${dataType} (${id || ''}) detected. ${this._enabled ? 'Try to trigger action...' : 'No action triggered'}`)

    if (this._listeners[dataType]) {
      this._listeners[dataType].forEach(currentListener => { currentListener(id) })
    } else {
      console.log(dataType + ' doesn\'t exist... ?')
    }
  }
}

export const crossbarService = new CrossbarService()

export default crossbarService
