






















import { PropOptions } from 'vue'
import { ChartDataset } from '@/views/CustomDashboard/definition'

export default {
  props: {
    stationDataset: {
      type: Object,
      required: true
    } as PropOptions<ChartDataset>,
    allowWindDirectionSet: {
      type: Boolean,
      default: true
    },
    onlyOneDataset: {
      type: Boolean,
      default: false
    },
    displayWBGTAndHeatIndex: { // Only 1 station available for now
      type: Boolean,
      default: false
    },
    displayLightIntensity: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    datasets () {
      const defaultDataSet = [{
        propName: 'datasetAirTemp',
        label: 'T° Air (°C)'
      }, {
        propName: 'datasetTrackTemp',
        label: 'T° Track (°C)'
      }, {
        propName: 'datasetWindAvg',
        label: 'Wind Avg (kph)'
      }, {
        propName: 'datasetWindGust',
        label: 'Wind Gust (kph)'
      }, {
        propName: 'datasetHumidity',
        label: 'Humidity (%)'
      }, {
        propName: 'datasetPressure',
        label: 'Pressure (hPa)'
      }, {
        propName: 'datasetRainIntensity',
        label: 'Rain intensity (mm/h)'
      }]
      if (this.displayWBGTAndHeatIndex) {
        defaultDataSet.push({
          propName: 'datasetWBGT',
          label: 'WBGT (°C)'
        }, {
          propName: 'datasetHeatIndex',
          label: 'Heat Index (°C)'
        })
      }
      if (this.displayLightIntensity) {
        defaultDataSet.push({
          propName: 'datasetLightIntensity',
          label: 'Light Intensity (Lx)'
        })
      }
      if (this.allowWindDirectionSet) {
        defaultDataSet.push({
          propName: 'datasetWindDirection',
          label: 'Wind direction (exclusive)'
        })
        defaultDataSet.push({
          propName: 'datasetCarWindDirection',
          label: 'Apparent wind direction (exclusive)'
        })
      }
      return defaultDataSet
    }
  }
}
