







































import Vue from 'vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'

export default Vue.extend({
  name: 'settings-dialog',
  components: {
    'ui-toggle-switch': ToggleSwitch,
    'ui-dialog': Dialog
  },
  props: {
    displayDrawing: {
      type: Boolean,
      default: false
    },
    all: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    activeIndex: 0
  }),
  computed: {
    allLength () {
      return this.all.length
    }
  },
  methods: {
    incrementIndex () {
      this.activeIndex < this.allLength - 1 && this.activeIndex++
    },
    decrementIndex () {
      this.activeIndex > 0 && this.activeIndex--
    },
    toogle (active: boolean) {
      this.$emit('toggle-display-drawing')
      if (active) {
        this.$emit('drawing-change', this.all[this.activeIndex])
      }
    }
  },
  watch: {
    all: {
      immediate: true,
      handler (newValue) {
        newValue && (this.activeIndex = newValue.length - 1)
      }
    },
    activeIndex (newValue) {
      this.$emit('drawing-change', this.all[newValue])
    }
  }
})
