import { StateDrawing } from './definitions'
import apiService from '@/services/api'
import { DateTime } from 'luxon'
import { stringifyDate } from '@/helpers/dates'
import { createStore } from 'vue-reactive-store'

import { vrsStoreApp } from '@/store/app/store'
import { WeatherEvent } from '../event/definitions'

const state: StateDrawing = {
  loading: false,
  error: null,
  data: null,
  all: null
}

const store = {
  name: 'drawing',
  state,
  computed: {
    dataToDisplay () {
      if (!store.state.all) return
      const drawingDataBeforeCurrentTime = store.state.all.filter(i => i.valueDate <= vrsStoreApp.state.data.referenceDate /* currentTime */)
      if (!drawingDataBeforeCurrentTime) return null
      return drawingDataBeforeCurrentTime[drawingDataBeforeCurrentTime.length - 1]
    }
  },
  actions: {
    /**
     * Fetch latest data from weather stations
     *
     * @param {array} stations
     */
    async fetchDrawingData (currentEventId: number, eventList: WeatherEvent[], currentTime: string, realtime: boolean, timezone: string, onlyBroadcasted: boolean) {
      store.state.loading = true

      // If you want broadcasted-only drawing, then only
      // need to fetch dranwings for the current event.
      if (onlyBroadcasted) {
        eventList = [eventList.find((e) => e.id === currentEventId)]
      }

      try {
        store.state.all = []
        for (const event of eventList) {
          const responseDrawing = await apiService.getDrawing(event.id, onlyBroadcasted)
          store.state.all.push(...responseDrawing.data.map(d => {
            const currentUpdateTime = DateTime.fromISO(d.value_date, { zone: timezone })
            return {
              id: d.id,
              data: d.data,
              event: event.id,
              eventYear: event.year,
              valueDate: d.value_date,
              updateTime: currentUpdateTime.toFormat('T \'on\' EEE dd MMM'),
              datestring: stringifyDate(currentUpdateTime),
              broadcasted: (!!d.broadcasted) || onlyBroadcasted
            }
          }))
        }
        store.state.all.sort((a, b) => a.datestring.localeCompare(b.datestring))

        if (realtime) {
          store.state.data = store.state.all
            .filter(d => d.event === currentEventId)
            .filter(d => d.broadcasted)
        } else {
          // find the closer information from the currentTime
          store.actions.computeDrawingDataToDisplay(currentTime, currentEventId)
        }
      } catch (e) {
        console.error(e)
        store.state.error = e
      }

      store.state.loading = false
    },

    computeDrawingDataToDisplay (currentTime: string, currentEventId: number) {
      if (!store.state.all) return
      const drawingDataBeforeCurrentTime = store.state.all
        .filter(d => d.event === currentEventId)
        .filter(d => d.datestring.localeCompare(currentTime))
        .filter(d => d.broadcasted)
      if (drawingDataBeforeCurrentTime) {
        store.state.data = drawingDataBeforeCurrentTime // [drawingDataBeforeCurrentTime.length - 1]
      } else {
        store.state.data = null
      }
    },

    findLatestDrawingDateString (): string | null {
      if (!store.state.all) return null
      const latestData = store.state.all.sort((a, b) => a.datestring.localeCompare(b.datestring))
      return latestData[latestData.length - 1].datestring
    },

    addDrawing (drawing: any, event: WeatherEvent) {
      const currentUpdateTime = DateTime.fromISO(drawing.value_date, { zone: event.timezone })
      store.state.all.push({
        id: drawing.id,
        event: event.id,
        eventYear: event.year,
        data: drawing.data,
        valueDate: drawing.value_date,
        updateTime: currentUpdateTime.toFormat('T \'on\' EEE dd MMM'),
        datestring: stringifyDate(currentUpdateTime),
        broadcasted: !!drawing.broadcasted
      })
    }
  }
}

export const fetchDrawingData = store.actions.fetchDrawingData
export const computeDrawingDataToDisplay = store.actions.computeDrawingDataToDisplay
export const addDrawing = store.actions.addDrawing

export const vrsStoreDrawing = createStore(store)
