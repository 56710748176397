import { UserPreference } from '@/services/preferences'
import { GenericState } from '../definitions'

export enum AppRoute {
  HOME = 'home',
  MEDIA_DASHBOARD = 'media_dashboard',
  MEDIA_DASHBOARD_SECTION = 'media_dashboardSection',
  MEDIA_MESSAGES = 'media_messages',
  CUSTOM_DASHBOARD = 'customDashboard',
  NOWCASTING = 'nowcasting',
  FORECAST = 'forecast',
  LOGIN = 'login',
  SIGNUP = 'signup',
  PRIVACY_POLICY = 'privacy_policy',
  LOST_PASSWORD = 'lost_password',
  RESET_PASSWORD = 'reset_password',
  OBSERVATIONS = 'observations',
  OBSERVATIONS_DETAILS = 'observationsDetails',
  STATISTICS = 'statistics',
  MESSAGES = 'messages',
  SETTINGS = 'settings',
  PROFILE = 'profile',
  NOTFOUND = 'notfound',
  CLIMATERECAP = 'climaterecap',
}
export interface AppQS {
  eventId?: string;
  mode?: string;
  referenceDate?: string;
  length?: number;
  zoomTimeline?: number;
  route?: AppRoute;
}
export interface App {
  referenceDate: string;
  /**
   * Number of minutes for the radar animation
   * period is [referenceDate - animationLength, referenceDate]
   */
  animationLength: number;
  currentPageDefaultAnimationLength: number;
  defaultAnimationLength: number;
  /**
   * Maximum date possible for show data
   */
  maximumTimeLive: string;
  eventId: number | string;
  eventTimezone: string;
  radarRange: [string, string];
  live: boolean;
  settings: UserPreference;
  isMobile: boolean;
  isDesktop: boolean;
  isiOS: boolean;
  isAndroid: boolean;
  fontBase: number;
  // For forecast items size
  sliderItemWidth: number;
  // this flag is here to detect the router is moving some vars
  // potentially strings, and Vue doesn't detect mutation on identical strings
  // cf https://forum.vuejs.org/t/watch-doesnt-fire-if-value-is-same/38002
  queryString: AppQS;
  /**
   * Timeline zoom
   */
  zoomTimeline: number;
  replaySettings: {
    enabled: boolean;
    weatherstation: boolean;
    weatherstationavgtemp: boolean;
    forecast: boolean;
    information: boolean;
    drawing: boolean;
  };
}

export type StateApp = GenericState<App>
