





























































































































































































































































































































































































/* eslint-disable no-case-declarations */

import Vue from 'vue'

import Player from '@/components/ui/Player/Player.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import MapLegend from '@/components/map/MapLegend/MapLegend.vue'
import MapSettings from '@/components/map/MapSettings/MapSettings.vue'
import Button from '@/components/ui/Button/Button.vue'
import RainInformation from '@/components/data/forecast/rain/RainInformation.vue'
import WeatherStationListing from '@/components/data/weatherstation/WeatherStationListing.vue'
import ServiceInformation from '@/components/data/information/ServiceInformation.vue'
import ForecastMix from '@/components/data/forecast/ForecastMix.vue'
import WeatherStationDialog from '@/components/data/weatherstation/WeatherStationDialog.vue'
import { fetchWeatherStationDataFilteredByDate } from '@/store/weatherstation/store'

import { fetchRadarBbox } from '@/store/time/store'

import preferences from '@/services/preferences'
import { vrsStore } from '@/store'
import { WeatherEvent } from '@/store/event/definitions'
import { convertDate } from '@/helpers/dates'

import { vrsStoreEvent } from '@/store/event/store'
import { computeForecastDataToDisplay } from '@/store/forecast/store'
import { computeInfosDataToDisplay, vrsStoreInformation } from '@/store/infos/store'
import { computeAvgTempDataToDisplay, fetchWeatherStationAverageTemperatureDataFilteredByDate } from '@/store/avgtemp/store'
import { findLatestForecastValueDateForSpecificType } from '@/store/forecast/helpers'

import { LatLng } from 'leaflet'
import { DateTime } from 'luxon'
import SaveDialog from '@/components/map/Draw/SaveDialog.vue'
import BroadcastDialog from '@/components/map/Draw/BroadcastDialog.vue'
import apiService from '@/services/api'
import CancelDialog from '@/components/map/Draw/CancelDialog.vue'
import { computeDrawingDataToDisplay } from '@/store/drawing/store'

export default Vue.extend({
  components: {
    'ui-player': Player,
    'ui-loader': Loader,
    'map-mapbox': () => import('@/components/map/MapboxGL/MapboxGL.vue'),
    'map-leaflet': () => import('@/components/map/Leaflet/Leaflet.vue'),
    'map-legend': MapLegend,
    'map-settings': MapSettings,
    'forecast-rain-information': RainInformation,
    'weather-station-listing': WeatherStationListing,
    'weather-station-dialog': WeatherStationDialog,
    'service-information': ServiceInformation,
    'forecast-mix': ForecastMix,
    'ui-button': Button,
    'drawing-save-dialog': SaveDialog,
    'drawing-broadcast-dialog': BroadcastDialog,
    'drawing-cancel-dialog': CancelDialog
  },
  data: () => ({
    /** @type {boolean} */
    isPlaying: false,
    /** @type {boolean} */
    wasPlayingBeforeEvent: false,
    /** @type {number} */
    numberOfForwardLayers: 5,
    showLegend: false,
    event: vrsStore.state.event,
    time: vrsStore.state.time,
    forecast: vrsStore.state.forecast,
    information: vrsStore.state.information,
    drawingStore: vrsStore.state.drawing,
    weatherStation: vrsStore.state.weatherStation,
    app: vrsStore.state.app,
    config: vrsStore.state.config,
    displayLayers: {
      osm: preferences['layer-osm'] !== undefined ? preferences['layer-osm'] : true,
      rainpath: preferences['layer-rainpath'] !== undefined ? preferences['layer-rainpath'] : false,
      /* we init weatherStations with the config var */
      weatherStations: preferences['layer-weatherStations'] !== undefined ? preferences['layer-weatherStations'] : vrsStore.modules.auth.computed.displayNowcastingOptions().stations === true,
      drawing: preferences['layer-drawing'] !== undefined ? preferences['layer-drawing'] : false
    },
    view: preferences.view,
    frameRate: preferences.frameRate,
    animationLengths: [15, 30, 60, 120, 180, 240, 300, 360],
    osmOpacity: preferences.osmOpacity,
    radarOpacity: preferences.radarOpacity,
    airParifOpacity: preferences.airParifOpacity,
    dataLayer: preferences.dataLayer,
    isLayerWindArrowsDisplayed: preferences.isLayerWindArrowsDisplayed,
    replaySettings: vrsStore.state.app.data.replaySettings,
    centerPreferenceMain: preferences.events?.[vrsStore.state.event.data?.id]?.mapMainCenter,
    centerPreferenceZoom: preferences.events?.[vrsStore.state.event.data?.id]?.mapZoomCenter,
    displayMapLegend: false,
    displayMapSettings: false,
    displayWeatherStationDialog: Object.values(preferences.stationDialogs ?? {}).filter(item => item !== undefined),
    /**
     * Memorize which widgets are minimized
     */
    /**
     * @type {
     *  rainInformation: { since: integer; notification: string }
     *  stations: { since: integer; notification: string }
     *  serviceInformation: { since: integer; notification: string }
     * }
     */
    minimizedWidgets: {},
    mapBackground: 'light' as 'light' | 'dark' | 'shadedrelief' | 'satellite',
    // is the map background a dark one (dark / satellite) or not
    mapDark: false,
    minY: 0,
    drawing: null,
    drawingModeEnabled: false,
    showDrawingCancelDialog: false,
    showDrawingConfirmationDialog: false,
    savingDrawing: false,
    drawingSaved: false,
    errorOnDrawingSaving: undefined,
    showDrawingBroadcastDialog: false,
    broadcastingDrawing: false,
    drawingBroadcasted: false,
    errorOnBroadcasting: undefined
  }),
  computed: {
    // currentTime
    // selectedRangeLocal
    ...vrsStore.modules.time.computed,
    allDataTimeRangesLocal: vrsStore.computed.allDataTimeRangesLocal,
    displayNowcastingOptions: vrsStore.modules.auth.computed.displayNowcastingOptions,
    displayHeaderAverageTemperature: vrsStore.modules.auth.computed.displayHeaderAverageTemperature,
    displayAirParif: vrsStore.modules.auth.computed.displayAirParifData,
    displayWBGTAndHeatIndex: vrsStore.modules.auth.computed.displayWBGTAndHeatIndex,
    displayLightIntensity: vrsStore.modules.auth.computed.displayLightIntensity,
    imageResolution: vrsStore.modules.app.computed.imageResolution,
    canManageDrawing: vrsStore.modules.auth.computed.canManageDrawing,
    timeoutBetweenImages () {
      return 1000 / this.frameRate
    },
    mapComponentToUse: vrsStore.modules.app.computed.mapComponentToUse,
    animationLength () {
      return this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1 ? vrsStore.state.app.data.animationLength : 0
    },
    displayAside () {
      const DNO = this.displayNowcastingOptions
      return DNO.stations || DNO.info || DNO.rain
    },
    currentWeatherStationData () {
      // display latest data only when live
      if (
        !this.app.data.live &&
        this.replaySettings.enabled &&
        this.replaySettings.weatherstation
      ) {
        return this.weatherStation?.dataByTimestamp?.map(wsd => (wsd[this.currentTime.local]))
      }
      return this.weatherStation.latest
    },
    displayGroundStationTrackOrWBGT () {
      return this.config.data.DISPLAY_NOWCASTING_GROUND_STATION_TRACK_WBGT
    }
  },
  methods: {

    changeAnimationLength (newValue: number) {
      if (this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1) {
        preferences.defaultAnimationLength = newValue
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length: newValue
        }
      })
    },

    togglePlay () {
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.play() : this.stop()
    },

    toggleLayer (layerName) {
      this.displayLayers[layerName] = !this.displayLayers[layerName]
      preferences['layer-' + layerName] = this.displayLayers[layerName]
    },

    toggleArrows () {
      this.isLayerWindArrowsDisplayed = !this.isLayerWindArrowsDisplayed
      preferences.isLayerWindArrowsDisplayed = this.isLayerWindArrowsDisplayed
    },

    updateMapBackground (name: 'light' | 'dark' | 'shadedrelief' | 'satellite') {
      this.mapBackground = name
      this.mapDark = ['dark', 'satellite'].indexOf(name) > -1
    },

    play () {
      vrsStore.state.time.indexTimesToDisplayUTC =
        (vrsStore.state.time.indexTimesToDisplayUTC + 1) % this.timesToDisplayUTC.length
      this.timeoutId = setTimeout(() => {
        this.play()
      }, this.timeoutBetweenImages)
    },
    stop () {
      clearTimeout(this.timeoutId)
    },

    fastBackward () {
      vrsStore.state.time.indexTimesToDisplayUTC = 0
    },
    backward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC > 1) {
        vrsStore.state.time.indexTimesToDisplayUTC--
      }
    },
    forward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC < this.timesToDisplayUTC.length - 1) {
        vrsStore.state.time.indexTimesToDisplayUTC++
      }
    },
    fastForward () {
      vrsStore.state.time.indexTimesToDisplayUTC = this.timesToDisplayUTC.length - 1
    },
    goToIndex (i) {
      if (i > 0 && i < this.timesToDisplayUTC.length) {
        vrsStore.state.time.indexTimesToDisplayUTC = i
      }
    },

    pauseAnimation () {
      this.wasPlayingBeforeEvent = this.isPlaying || this.wasPlayingBeforeEvent
      this.stop()
    },
    resumeAnimation (map: 'zoom' | 'main', center?: LatLng) {
      this.wasPlayingBeforeEvent && this.play()
      this.wasPlayingBeforeEvent = false
      /**
       * if center is set, memorize it in user pref
       * for this event, and for this map
       */
      if (center) {
        preferences.updateEventMapCenter(vrsStore.state.event.data.id, map, center)
        switch (map) {
          case 'zoom':
            this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
            break
          case 'main':
            this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
            break
        }
      }
    },
    onResetMapCenter () {
      preferences.updateEventMapCenter(vrsStore.state.event.data.id, 'zoom')
      preferences.updateEventMapCenter(vrsStore.state.event.data.id, 'main')
      this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
      this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
    },

    onOsmOpacityChange (value: number): void {
      this.osmOpacity = value
    },
    onRadarOpacityChange (value: number): void {
      this.radarOpacity = value
    },
    onAirParifOpacityChange (value: number): void {
      this.airParifOpacity = value
    },
    onUpdateDataLayer (value: 'airquality' | 'radar'): void {
      this.dataLayer = value
      this.time.timeComputation = value
    },
    fetchData () {
      if (!vrsStore.state.event.data) return
      if (!this.selectedRangeLocal) return
      fetchWeatherStationDataFilteredByDate(
        vrsStore.state.event.data.stations.map(s => s.id.toString()),
        convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
        convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
      )
      fetchWeatherStationAverageTemperatureDataFilteredByDate(
        vrsStore.state.event.data.id,
        convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
        convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
      )
    },
    refreshReplayData () {
      if (!this.replaySettings.enabled) return
      if (this.replaySettings.forecast) {
        computeForecastDataToDisplay(
          this.currentTime.luxonDate,
          vrsStoreEvent.state.data.timezone,
          vrsStoreEvent.state.data.sessions
        )
      }
      if (this.replaySettings.information) {
        computeInfosDataToDisplay(this.currentTime.local)
      }
      if (this.replaySettings.drawing) {
        computeDrawingDataToDisplay(this.currentTime.local, vrsStoreEvent.state.data.id)
      }
      if (this.replaySettings.weatherstationavgtemp) {
        computeAvgTempDataToDisplay(this.currentTime.local)
      }
    },
    resetForecastData () {
      const referenceDateLuxon = convertDate(vrsStore.modules.app.state.data.referenceDate, vrsStoreEvent.state.data.timezone)

      computeForecastDataToDisplay(
        referenceDateLuxon,
        vrsStoreEvent.state.data.timezone,
        vrsStoreEvent.state.data.sessions
      )
    },
    resetInformationData () {
      computeInfosDataToDisplay(vrsStore.modules.app.state.data.referenceDate)
    },
    resetDrawingData () {
      computeDrawingDataToDisplay(vrsStore.modules.app.state.data.referenceDate, vrsStoreEvent.state.data.id)
    },
    resetAvgTempData () {
      computeAvgTempDataToDisplay(vrsStore.modules.app.state.data.referenceDate)
    },

    /**
     * Maximized action
     */
    toggleMaximized (widget: string) {
      /**
       * We init this actual state for retrocompatibility
       * Maximized widget state is a 3.2.x feature
       *
       * We store also the since params,
       * for notification purpose.
       * It allow us to compute how many forecast have been missed
       */
      /**
       * Is the widget already minimized ?
       */
      if (this.minimizedWidgets[widget]) {
        this.$set(this.minimizedWidgets, widget, null)
      } else {
        this.$set(this.minimizedWidgets, widget, {
          since: DateTime.fromMillis(Date.now(), { setZone: true, zone: vrsStoreEvent.state.activeEvent.timezone })
            .toFormat('yyyyMMddHHmm00'),
          notifications: null
        })
      }
    },
    /**
     * Compute all missed new data for minimized widgets
     * and not "synced" (but realtime)
     * and type of widget implemented
     */
    refreshNotifications () {
      ['rainInformation', 'serviceInformation'].forEach(widget => {
        let notificationValue = null
        if (this.minimizedWidgets[widget]) {
          const minimizationDatestring = this.minimizedWidgets[widget].since
          switch (widget) {
            /**
             * For rain data, we try to know if since > latest rain report
             */
            case 'rainInformation':
              const latestRainValueDateString = findLatestForecastValueDateForSpecificType(this.forecast.apiData, 'rain')
              notificationValue = latestRainValueDateString > minimizationDatestring ? '!' : null
              break
            case 'serviceInformation':
              const latestServiceInformationDateString = vrsStoreInformation.actions.findLatestInfoDateString()
              notificationValue = latestServiceInformationDateString > minimizationDatestring ? '!' : null
              break
          }
          this.$set(this.minimizedWidgets[widget], 'notifications', notificationValue)
        }
      })
    },
    addWeatherStationDialog (stationId: string | number) {
      const id = Math.floor((Math.random() * 100) + 1)
      const dialog = {
        id: id,
        stationId: stationId,
        position: {
          top: 3 + this.displayWeatherStationDialog.length * 3.4 + 'rem',
          left: 'calc(100% - ' + (35 + this.displayWeatherStationDialog.length) + 'rem)'
        },
        minimized: false
      }
      preferences.updateStationDialogs(id, dialog)
      this.displayWeatherStationDialog.push(dialog)
    },
    getWeatherStation (stationId: string | number) {
      return this.event.data.stations.find(item => +item.properties.reference === +stationId)
    },
    getWeatherStationData (stationId: string | number) {
      const index = this.event.data.stations.findIndex(item => +item.properties.reference === +stationId)
      if (vrsStore.state.weatherStation.data && index < vrsStore.state.weatherStation.data.length) {
        return vrsStore.state.weatherStation.data[index]
      } else {
        return null
      }
    },
    removeWeatherStationDialog (id: number) {
      const index = this.displayWeatherStationDialog.findIndex(item => item.id === id)
      if (index > -1) {
        this.displayWeatherStationDialog.splice(index, 1)
      }
      preferences.updateStationDialogs(id, undefined)
    },

    openDrawingConfirmationDialog (drawing: any) {
      this.drawing = drawing
      this.showDrawingConfirmationDialog = true
    },
    openDrawingCancelDialog (drawing: any[]) {
      if (drawing && drawing.length) {
        this.showDrawingCancelDialog = true
      } else {
        this.drawingModeEnabled = false
      }
    },
    async doSaveDrawing (doBroadcast: boolean) {
      this.savingDrawing = true
      try {
        // If an `id` is present, then it's a modification.
        if (this.drawing.id) {
          // On drawing update, will always broadcast modified draw.
          await apiService.patchDrawing(this.event.data.id, this.drawing.id, this.drawing.data)

          if (doBroadcast) {
            await apiService.broadcastDrawing(this.event.data.id, this.drawing.id)
          }
        } else {
          const { data: drawing } = await apiService.postDrawing(this.event.data.id, this.drawing.data)

          if (doBroadcast) {
            drawing.broadcasted = true
            await apiService.broadcastDrawing(this.event.data.id, drawing.id)
          }

          vrsStore.modules.drawing.actions.addDrawing(drawing, this.event.data)
        }

        this.drawingSaved = true
        this.drawingModeEnabled = false
      } catch (e) {
        console.error(e)
        this.errorOnDrawingSaving = 'An error occured. Please try again.'
      }
      this.savingDrawing = false
    },
    drawingSaveCancel () {
      this.savingDrawing = false
      this.drawingSaved = false
      this.drawing = null
      this.drawingId = null
      this.errorOnDrawingSaving = undefined
      this.showDrawingConfirmationDialog = false
    },
    closeDrawingConfirmationDialog () {
      this.savingDrawing = false
      this.drawingSaved = false
      this.drawing = null
      this.drawingId = null
      this.errorOnDrawingSaving = undefined
      this.drawingModeEnabled = false
      this.showDrawingConfirmationDialog = false
    },
    openDrawingBroadcastDialog (drawing: any) {
      this.drawing = drawing
      this.showDrawingBroadcastDialog = true
    },
    async doBroadcastDrawing () {
      this.broadcastingDrawing = true
      try {
        await apiService.broadcastDrawing(this.event.data.id, this.drawing.id)

        this.drawingBroadcasted = true
        this.drawingModeEnabled = false
      } catch (e) {
        console.error(e)
        this.errorOnBroadcasting = 'An error occured. Please try again.'
      }
      this.broadcastingDrawing = false
    },
    drawingBroadcastCancel () {
      this.broadcastingDrawing = false
      this.drawingBroadcasted = false
      this.drawing = false
      this.errorOnBroadcasting = undefined
      this.showDrawingBroadcastDialog = false
    },
    closeDrawingBroadcastDialog () {
      this.broadcastingDrawing = false
      this.drawingBroadcasted = false
      this.drawing = false
      this.errorOnDrawingBroadcasting = undefined
      this.drawingModeEnabled = false
      this.showDrawingBroadcastDialog = false
    }
  },
  beforeDestroy () {
    this.stop()
  },
  mounted () {
    this.minY = this.$refs.uiPlayer.$el.offsetHeight
  },
  watch: {
    'event.data': {
      immediate: true,
      async handler (newValue: WeatherEvent) {
        if (newValue) {
          await fetchRadarBbox(newValue.id)
          this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
          this.centerPreferenceZoom = preferences.events?.[vrsStore.state.event.data.id]?.mapZoomCenter
        }
      }
    },
    'forecast.data': {
      deep: true,
      handler: 'refreshNotifications'
    },
    'information.data': {
      deep: true,
      handler: 'refreshNotifications'
    },
    selectedRangeLocal: {
      immediate: true,
      handler (newRange, oldRange) {
        if (!newRange) return
        if (!oldRange) {
          this.fetchData()
          return
        }
        if (newRange[0] === oldRange[0] && newRange[1] === oldRange[1]) return
        this.fetchData()
      }
    },

    frameRate (newValue) {
      preferences.frameRate = newValue
    },
    view (newValue) {
      preferences.view = newValue
    },
    osmOpacity (newValue: number) {
      preferences.osmOpacity = newValue
    },
    radarOpacity (newValue: number) {
      preferences.radarOpacity = newValue
    },
    airParifOpacity (newValue: number) {
      preferences.airParifOpacity = newValue
    },
    currentTime: 'refreshReplayData',
    replaySettings: {
      handler (newValue) {
        /**
         * if the user disable replay,
         * use the reference date once
         * for forecast and infos data
         */
        if (!newValue.enabled) {
          this.resetForecastData()
          this.resetInformationData()
        }
        /**
         * if the user disable replay for forecast,
         * reset it to the reference date
         */
        if (newValue.enabled && !newValue.forecast) {
          this.resetForecastData()
        }
        /**
         * if the user disable replay for weatherstation,
         * it's automatically computed
         */
        /**
         * if the user disable replay for information,
         * reset it to the reference date
         */
        if (newValue.enabled && !newValue.information) {
          this.resetInformationData()
        }
        /**
         * if the user disable replay for drawing,
         * reset it to the reference date
         */
        if (newValue.enabled && !newValue.drawing) {
          this.resetDrawingData()
        }
        if (newValue.enabled && !newValue.weatherstationavgtemp) {
          this.resetAvgTempData()
        }
      },
      deep: true
    },
    'replaySettings.weatherstation' (newValue) {
      if (this.replaySettings.enabled && newValue === true) {
        this.fetchData()
      }
    },
    'replaySettings.weatherstationavgtemp' (newValue) {
      if (this.replaySettings.enabled && newValue === true) {
        this.fetchData()
      }
    }
  }
})
