





























































import Vue from 'vue'
import Chart from './Chart.vue'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import { getDatasets } from './helper'
import Loader from '@/components/ui/Loader/Loader.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import Button from '@/components/ui/Button/Button.vue'
import MultiSelect from '@/components/ui/MultiSelect/MultiSelect.vue'
import { setChartDatasetRanges } from '@/views/CustomDashboard/helper'
import { datasetsConfiguration } from '@/views/CustomDashboard/definition'
import preferences from '@/services/preferences'

export default Vue.extend({
  name: 'WeatherStation',
  components: {
    Chart,
    'ui-loader': Loader,
    'ui-button': Button,
    'ui-dialog': Dialog,
    'ui-multiselect': MultiSelect
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    station: { default: () => ({}) },
    stationData: { default: () => ([]) },
    loading: {
      type: Boolean,
      default: false
    },
    minY: {
      type: Number,
      required: false
    },
    displaySession: {
      type: Boolean,
      default: true
    },
    displayWBGTAndHeatIndex: {
      type: Boolean,
      default: false
    },
    displayLightIntensity: {
      type: Boolean,
      default: false
    },
    sessions: {
      default: () => ([])
    },
    selectedRangeLocal: {
      default: () => ([])
    },
    trackOrWbgt: {
      type: String,
      default: 'track'
    }
  },
  data: () => ({
    datasetTemperature: null,
    datasetWind: null,
    datasetHuPressure: null,
    datasetRain: null,
    datasetWindDirection: {
      x: [],
      y: []
    },
    datasetWindRoseDirection: null,
    minTemperature: null,
    maxTemperature: null,
    minWind: null,
    maxWind: null,
    minHumidity: null,
    maxHumidity: null,
    minPressure: null,
    maxPressure: null,
    maxRain: null,
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 5,
    humidityAddition: 1,
    datasetSelected: [],
    position: { top: 0, left: 0 },
    size: { width: '450px', height: '300px' },
    minimized: false
  }),
  computed: {
    stationId () {
      return this.station.properties?.reference ?? '-'
    },
    datasetOptions () {
      const defaultDataSet: {[key: string]: {libelle: string; short: string; exclusive?: boolean }} = {
        datasetAirTemp: { libelle: 'T° Air (°C)', short: 'T° Air' },
        datasetTrackTemp: { libelle: 'T° Track (°C)', short: 'T° Track' },
        datasetWindAvg: { libelle: 'Wind Avg (kph)', short: 'Wind Avg' },
        datasetWindGust: { libelle: 'Wind Gust (kph)', short: 'Wind Gust' },
        datasetHumidity: { libelle: 'Humidity (%)', short: 'Humidity' },
        datasetPressure: { libelle: 'Pressure (hPa)', short: 'Pressure' },
        datasetRainIntensity: { libelle: 'Rain intensity (mm/h)', short: 'Rain intensity' }
      }
      if (this.displayWBGTAndHeatIndex) {
        defaultDataSet.datasetWBGT = { libelle: 'WBGT (°C)', short: 'WBGT' }
        defaultDataSet.datasetHeatIndex = { libelle: 'Heat Index (°C)', short: 'Heat Index' }
      }
      if (this.displayLightIntensity) {
        defaultDataSet.datasetLightIntensity = { libelle: 'Light Intensity (Lx)', short: 'Light Intensity' }
      }
      defaultDataSet.datasetWindDirection = { libelle: 'Wind direction (exclusive)', short: 'Wind direction', exclusive: true }
      defaultDataSet.datasetCarWindDirection = { libelle: 'Apparent wind direction (exclusive)', short: 'Apparent wind direction', exclusive: true }

      return defaultDataSet
    },
    groundStationChartDataset () {
      if (this.stationData) {
        const datasets = getDatasets(this.stationData)
        if (!datasets) return null

        const chartDataset = {
          plotlyDataset: [],
          twoYAxes: false,
          windDataset: null,
          displayWind: false,
          displayCarWindDirection: false,
          rangeMin: null,
          rangeMax: null,
          rangeMode: 'normal',
          range2Min: null,
          range2Max: null,
          range2Mode: 'normal'
        }

        /**
         * The ground station widget
         * could have one or two dataset.
         *
         * If the first dataset is the wind direction,
         * we'll not try to add other dataset as the wind direction
         * is represented with a spider chart.
         */
        if (this.datasetSelected.length === 0) return chartDataset

        const firstSelectedDataset = this.datasetSelected[0]
        const isFirstSelectedDatasetWindDirection = firstSelectedDataset === 'datasetWindDirection' || firstSelectedDataset === 'datasetCarWindDirection'

        if (firstSelectedDataset === 'datasetCarWindDirection') {
          chartDataset.displayCarWindDirection = true
        }
        // Get datasetConf for each selected dataset
        // From unit, we sort data
        const datasetByUnit = this.datasetSelected.reduce((acc, selectedDataset) => {
          const currentDataset = datasetsConfiguration[selectedDataset]
          if (acc[currentDataset.datasetScale]) {
            acc[currentDataset.datasetScale].push(currentDataset)
          } else {
            acc[currentDataset.datasetScale] = [currentDataset]
          }
          return acc
        }, {})

        const colorsAvailable = ['#b9a26c', '#03bde4', '#80ff7e', '#DD66D8', '#ffd402', '#ff2902', '#830202']

        // First axis
        const dataExist: Record<string, any>[] = Object.values(datasetByUnit)
        if (dataExist?.length > 0) {
          const firstDatasetConfiguration = dataExist[0]
          firstDatasetConfiguration.forEach((datasetConf, index) => {
            const firstDataset = datasets?.[datasetConf.datasetKey]
            datasetConf.datasetBindings.forEach(binding => {
              const { index: indexBindings, ...bindings } = binding
              if (isFirstSelectedDatasetWindDirection) {
                chartDataset.plotlyDataset = firstDataset
              } else {
                const currentDataset = firstDataset[indexBindings]
                chartDataset.plotlyDataset.push({
                  ...firstDataset[indexBindings],
                  label: datasetConf.datasetKey,
                  yaxis: 'y',
                  marker: {
                    color: colorsAvailable[index]
                  }
                })
                if (!currentDataset.isAnnotation) {
                  setChartDatasetRanges(
                    chartDataset,
                    currentDataset,
                    datasetConf.datasetScale
                  )
                }
              }
              Object.keys(bindings).forEach(b => { chartDataset[b] = bindings[b] })
            })
          })
        }

        // Second axis
        if (!isFirstSelectedDatasetWindDirection && (this.datasetSelected.length > 1 && dataExist[1])) {
          const secondDatasetConfiguration = dataExist[1]

          const yaxis = 'y2'
          chartDataset.twoYAxes = true
          const rangeMinKey = 'range2Min'
          const rangeMaxKey = 'range2Max'
          const rangeModeKey = 'range2Mode'

          secondDatasetConfiguration.forEach((datasetConf, index) => {
            const secondDataset = datasets[datasetConf.datasetKey]
            datasetConf.datasetBindings.forEach(binding => {
              const currentDataset = secondDataset[binding.index]
              chartDataset.plotlyDataset.push({
                ...currentDataset,
                label: datasetConf.datasetKey,
                yaxis,
                marker: {
                  color: colorsAvailable[dataExist[0].length + index]
                }
              })
              if (!currentDataset.isAnnotation) {
                setChartDatasetRanges(
                  chartDataset,
                  currentDataset,
                  secondDatasetConfiguration.datasetScale,
                  rangeMinKey,
                  rangeMaxKey,
                  rangeModeKey
                )
              }
            })
          })
        } else {
          chartDataset.twoYAxes = false
        }

        return chartDataset
      }
      return null
    },
    dialogStyle () {
      if (this.minimized) {
        return {
          ...this.position,
          width: this.size.width
        }
      } else {
        return {
          ...this.position,
          ...this.size
        }
      }
    }
  },
  methods: {
    updateDatasetSelected (datasets) {
      this.datasetSelected = datasets
      this.updatePreferences()
    },
    updatePreferences () {
      preferences.updateStationDialogs(this.id, {
        id: this.id,
        stationId: this.stationId,
        datasetSelected: this.datasetSelected,
        position: this.position,
        minimized: this.minimized,
        size: this.size
      })
    }
  },
  mounted () {
    this.datasetSelected = preferences.stationDialogs[this.id]?.datasetSelected ?? ['datasetAirTemp', 'datasetTrackTemp']
    this.minimized = preferences.stationDialogs[this.id]?.minimized ?? false
    if (preferences.stationDialogs[this.id]?.position) {
      this.position = preferences.stationDialogs[this.id].position
    }
    if (preferences.stationDialogs[this.id]?.size) {
      this.size = preferences.stationDialogs[this.id].size
    }
  },
  watch: {
    stationData: {
      immediate: true,
      handler (newData: WeatherStationData[]) {
        const datasets = getDatasets(newData, this.trackOrWbgt)
        datasets && Object.keys(datasets).forEach(key => {
          this[key] = datasets[key]
        })
      }
    },
    position () {
      this.updatePreferences()
    },
    minimized () {
      this.updatePreferences()
    },
    size () {
      if (this.$refs.chart) {
        this.$refs.chart.resizeListener()
      }
      this.updatePreferences()
    }
  }
})
