





























































import Vue from 'vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'

export default Vue.extend({
  name: 'new-drawing-dialog',
  components: {
    'ui-dialog': Dialog,
    'ui-button': Button
  },
  props: {
    displayDrawing: {
      type: Boolean,
      required: true
    },
    all: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    currentDrawing: null
  }),
  computed: {
    allLength () {
      return this.all.length
    },
    drawingByYear () {
      return this.all.reduce((result, drawing) => {
        (result[drawing.eventYear] = result[drawing.eventYear] || []).push(drawing)
        return result
      }, {})
    }
  },
  watch: {
    currentDrawing (newValue) {
      if (!this.displayDrawing) {
        this.$emit('toggle-display-drawing')
      }
      this.$emit('drawing-change', newValue)
    }
  }
})
