






























import Vue from 'vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'

export default Vue.extend({
  name: 'cancel-dialog',
  components: {
    'ui-button': Button,
    'ui-dialog': Dialog
  }
})
