













































































import Vue from 'vue'
import Button from '@/components/ui/Button/Button.vue'
import { convertDate, getTimeFromString } from '@/helpers/dates'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import WetBulbGlobeTemperature from '@/components/data/weatherstation/WetBulbGlobeTemperature/WetBulbGlobeTemperature.vue'
import LightIntensity from '@/components/data/weatherstation/LightIntensity/LightIntensity.vue'

@Component({
  components: {
    'ui-button': Button,
    WetBulbGlobeTemperature,
    LightIntensity
  }
})
export default class AverageIndicatorPopin extends Vue {
  @Prop()
  public loading: boolean;

  @Prop()
  public indicatorLabel: string;

  // Array of two string elements, [ dateFrom, dateEnd ]
  // in yyyyMMddHHmmss format
  @Prop({
    default: () => ([
      '20210101000000',
      '20210110000000'
    ])
  })
  public dateRange: [string, string]

  form: { from: string; to: string }

  get dates (): Array<{value: string; datestring: string}> {
    if (!this.dateRange || this.dateRange?.length !== 2) return []

    const dateStart = convertDate(this.dateRange[0].toString())
    const dateEnd = convertDate(this.dateRange[1].toString())

    const dates = []
    for (
      let date = dateStart;
      date < dateEnd;
      date = date.plus({ days: 1 })
    ) {
    // We need value to display in select menu
    // and datestring to pass on handle function (and send to TimesProvider)
      dates.push({
        value: date.toFormat('yyyy-MM-dd'),
        datestring: date.toFormat('yyyyMMdd')
      })
    }
    return dates
  }

  data (): { form: { from: string; to: string }} {
    return {
      form: {
        from: this.dateRange[0],
        to: this.dateRange[1]
      }
    }
  }

  getDate (timeString: string) {
    return timeString ? timeString.substring(0, 8) : ''
  }

  getTimeFromString = getTimeFromString

  changeCurrentTime (e, model: 'from' | 'to', type) {
    let dateString
    let timeString

    switch (type) {
      case 'date':
        dateString = e.target.value
        timeString = this.form[model].substring(8, 12)
        break
      case 'time':
        dateString = this.form[model].substring(0, 8)
        timeString = e.target.value.replace(':', '')
        break
    }
    this.form[model] = `${dateString}${timeString}00`
  }
}
