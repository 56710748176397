

































































import Vue from 'vue'
import ForecastDaily from './daily/ForecastDaily.vue'
import ForecastHourly from './hourly/ForecastHourly.vue'
import { convertDate, stringifyDate } from '@/helpers/dates'

export default Vue.extend({
  components: {
    ForecastDaily,
    ForecastHourly
  },
  props: {
    title: {
      type: String,
      default: 'Forecast'
    },
    displayTitle: {
      type: Boolean,
      default: false
    },
    /**
     * Props for hourly component
     */
    displaySession: {
      type: Boolean,
      default: false
    },
    displayPreviousNight: {
      type: Boolean,
      default: true
    },
    displayHeatIndex: {
      type: Boolean,
      default: false
    },
    displayHumidity: {
      type: Boolean,
      default: false
    },
    displayBydayPressure: {
      type: Boolean,
      default: true
    },
    displayHourlyPressure: {
      type: Boolean,
      default: true
    },
    displayGust: {
      type: Boolean,
      default: true
    },
    displayTrackMinMax: {
      type: Boolean,
      default: false
    },
    displayChanceOfRain: {
      type: Boolean,
      default: false
    },
    displayHourlyInfo: {
      type: Boolean,
      default: true
    },
    hourly: {
      // type: Array
    },
    displayDay: {
      type: Boolean,
      default: true
    },
    flattenData: {
      // type: Array,
      default: () => ([])
    },
    flattenDataIndex: {
      type: Number,
      default: 0
    },
    fontBase: Number,
    loading: Boolean,
    isMobile: Boolean,
    isDesktop: Boolean,
    isLegendVisible: {
      type: Boolean,
      default: true
    },
    sliderItemWidth: Number,
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      required: false
    },
    gustsOverLimit: {
      type: Array,
      required: false
    },
    windOverLimit: {
      type: Array,
      required: false
    },
    heatIndexOverLimit: {
      type: Number,
      required: false
    },
    humidityOverLimit: {
      type: Number,
      required: false
    },
    pressureOverLimit: {
      type: Number,
      required: false
    },
    trackMinOverLimit: {
      type: Number,
      required: false
    },
    trackMaxOverLimit: {
      type: Number,
      required: false
    },
    trackOrWbgt: {
      type: String,
      default: 'track'
    },
    /**
     * Props for daily component
     */
    daily: {
      type: Object,
      default () {
        return {
          header: '',
          data: []
        }
      }
    },
    defaultForecastInfo: String,
    apiData: Object,
    currentTime: String,
    timezone: String
  },
  computed: {
    displayHourly () {
      return this.flattenData && this.flattenData.length > 0 && !this.currentTimeBeyondEnd
    },
    displayDaily () {
      return this.daily && this.daily.data.length > 0 && !this.currentTimeBeyondEnd
    },
    currentTimeBeyondEnd () {
      if (!this.currentTime || !this.timezone) return true
      const currentDateTime = convertDate(this.currentTime, this.timezone)
      return (
        this.apiData &&
        this.apiData.range &&
        this.apiData.range[1] &&
        stringifyDate(currentDateTime) > this.apiData.range[1]
      )
    },
    currentTimeBeforeBegin () {
      if (!this.currentTime || !this.timezone) return false
      const currentDateTime = convertDate(this.currentTime, this.timezone)
      return (
        this.apiData &&
        this.apiData.range &&
        this.apiData.range[1] &&
        stringifyDate(currentDateTime) < this.apiData.range[0]
      )
    }
  }
})
