









/* eslint-disable no-console */
import Vue from 'vue'
import Header from '@/views/Header/Header.vue'
import MixinPageVisibility from '@/mixins/pageVisibility'
import { vrsStore } from '@/store'

export default Vue.extend({
  components: {
    'layout-header': Header
  },
  computed: {
    withHeader () {
      return this.$route.meta.withHeader
    }
  },
  /**
   * Here we manage the fact that the app can "exit"
   * from a sleepy state.
   * In this case, we refresh all data.
   */
  mixins: [
    MixinPageVisibility(async (isVisible) => {
      console.log('[Visibility] Page visibility has changed :', isVisible)
      if (isVisible && vrsStore.state.app.data.live) {
        console.log('[Visibility] Event is live, reloading data...')
        const eventId = vrsStore.state.event.data?.id
        if (!eventId) return

        /**
         * Reload event
         */
        await vrsStore.modules.event.actions.fetchEvent(eventId, true)
        /**
         * Reload forecast data
         */
        await vrsStore.modules.forecast.actions.fetchForecastData(eventId, vrsStore.state.event.data?.timezone)

        /**
         * Reload AirParif data if needded
         */
        if (vrsStore.modules.auth.computed.displayAirParifData()) {
          await vrsStore.modules.airparif.actions.fetchAirParifData(eventId)
        }

        /**
         * Reload weather station data
         */
        await vrsStore.modules.weatherStation.actions.fetchWeatherStationDataLatest(
          vrsStore.state.event.data?.stations?.map(s => s.id.toString())
        )

        /**
         * Reload messages / infos
         */
        await vrsStore.modules.message.actions.fetchMessages(eventId)
        await vrsStore.modules.information.actions.fetchInfosData(
          eventId,
          vrsStore.state.app.data.referenceDate,
          vrsStore.state.app.data.live,
          vrsStore.state.event.data.timezone
        )
        await vrsStore.modules.drawing.actions.fetchDrawingData(
          eventId,
          vrsStore.state.event.state.events.filter(e => e.code === vrsStore.state.event.state.data.code),
          vrsStore.state.app.data.referenceDate,
          vrsStore.state.app.data.live,
          vrsStore.state.event.data.timezone,
          !vrsStore.modules.auth.computed.canManageDrawing()
        )
      } else {
        console.log('[Visibility] Page is not visible anymore or event is not live. isVisible', isVisible, 'is live', vrsStore.state.app.data.live)
      }
    })
  ],
  mounted () {
    setInterval(() => {
      this.$matomo && this.$matomo.ping()
    }, 15000)
  }
})
