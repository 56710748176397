


























































import Button from '@/components/ui/Button/Button.vue'

export default {
  components: {
    'ui-button': Button
  },
  props: {
    title: {
      type: String,
      default: 'Dialog title'
    },
    closable: {
      type: Boolean,
      default: true
    },
    minimizable: {
      type: Boolean,
      default: false
    },
    minimized: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: Number,
      required: false
    },
    minWidth: {
      type: Number,
      required: false
    },
    minY: {
      type: Number,
      required: false
    },
    mainClass: {
      type: String,
      default: 'mt-2 p-4'
    }
  },
  data () {
    return {
      isDragging: false,
      isResizing: false,
      offset: [0, 0],
      mousePosition: { x: null, y: null }
    }
  },
  methods: {
    handleMouseDown (event) {
      if (this.draggable && event.target.tagName === 'HEADER') {
        this.isDragging = true
        this.offset = [
          this.$refs.draggableModal.offsetLeft - event.clientX,
          this.$refs.draggableModal.offsetTop - event.clientY
        ]
      } else if (event.target.classList.contains('resizeHandler')) {
        this.isResizing = true
        this.offset = [
          this.$refs.draggableModal.offsetWidth,
          this.$refs.draggableModal.offsetHeight
        ]
        this.mousePosition = {
          x: event.clientX,
          y: event.clientY
        }
      }
    },
    handleMouseUp () {
      this.isDragging = false
      this.isResizing = false
    },
    handleMouseMove (event) {
      event.preventDefault()
      if (this.isDragging) {
        this.mousePosition = {
          x: event.clientX,
          y: event.clientY
        }
        this.$refs.draggableModal.style.left = (this.mousePosition.x + this.offset[0]) + 'px'
        const nextTop = this.mousePosition.y + this.offset[1]
        if (this.minY !== undefined) {
          this.$refs.draggableModal.style.top = Math.max(this.minY, nextTop) + 'px'
        } else {
          this.$refs.draggableModal.style.top = nextTop + 'px'
        }
        this.$refs.draggableModal.style.bottom = 'auto'

        this.$emit('position', {
          top: this.$refs.draggableModal.style.top,
          left: this.$refs.draggableModal.style.left
        })
      }
      if (this.isResizing) {
        const nextWidth = this.offset[0] + event.clientX - this.mousePosition.x
        if (this.minWidth === undefined) {
          this.$refs.draggableModal.style.width = nextWidth + 'px'
        } else {
          this.$refs.draggableModal.style.width = Math.max(this.minWidth, nextWidth) + 'px'
        }

        const nextHeight = this.offset[1] + event.clientY - this.mousePosition.y
        if (this.minHeight === undefined) {
          this.$refs.draggableModal.style.height = nextHeight + 'px'
        } else {
          this.$refs.draggableModal.style.height = Math.max(this.minHeight, nextHeight) + 'px'
        }

        this.$emit('size', {
          width: this.$refs.draggableModal.style.width,
          height: this.$refs.draggableModal.style.height
        })
      }
    },
    toggleMinimized () {
      this.$emit('minimized', !this.minimized)
    }
  },
  mounted () {
    this.$refs.draggableModal.addEventListener('mousedown', (event) => this.handleMouseDown(event), true)
    if (this.$refs.resizeHandler) {
      this.$refs.resizeHandler.addEventListener('mousedown', (event) => this.handleMouseDown(event), true)
    }
    document.addEventListener('mouseup', () => this.handleMouseUp(), true)
    document.addEventListener('mousemove', (event) => this.handleMouseMove(event), true)
  },
  beforeDestroy () {
    this.$refs.draggableModal.removeEventListener('mousedown', this.handleMouseDown)
    document.removeEventListener('mouseup', this.handleMouseUp)
    document.removeEventListener('mousemove', this.handleMouseMove)
  }
}
