









































































import Vue from 'vue'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  name: 'save-dialog',
  components: {
    'ui-loader': Loader,
    'ui-toggle-switch': ToggleSwitch,
    'ui-button': Button,
    'ui-dialog': Dialog
  },
  props: {
    drawing: {
      type: Object,
      default: null
    },
    eventActive: {
      type: Boolean
    },
    saving: {
      type: Boolean,
      default: false
    },
    saved: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, undefined],
      default: undefined
    }
  },
  data () {
    return {
      doBroadcast: false
    }
  },
  computed: {
    isEdition () {
      return !!this.drawing.id
    },
    alreadyBroadcasted () {
      return !!this.drawing.broadcasted
    },
    title () {
      if (this.isEdition) {
        return 'Save modifications on drawing "' + this.drawing.updateTime + '"'
      } else {
        return 'Save the drawing'
      }
    }
  }
})
