import apiService from '@/services/api'
import preferences from '@/services/preferences'
import crossbarService from '@/services/crossbar'
import { createStore } from 'vue-reactive-store'
import { SignUpParameters, UserPermissions } from './definitions'
import * as Sentry from '@sentry/vue'

import { vrsStoreEvent } from '@/store/event/store'
import { vrsStoreConfig } from '../config/store'

let delayTimeout = null
function delayRefreshToken () {
  // enable a timer to refresh token before end of validity
  // Delay is 10 seconds before to be sure to not miss it
  const delay = JWT_EXPIRATION_DELTA * 24 * 60 * 60 * 1000 - 10 * 1000
  clearTimeout(delayTimeout)
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  delayTimeout = setTimeout(() => store.actions.refreshToken(), delay)
}

const store = {
  name: 'auth',
  state: {
    loading: false,
    error: null,
    data: {
      isAuthenticated: !!preferences.token,
      user: null,
      permissions: preferences.permissions
    }
  },
  computed: {
    displayMenuOptions () {
      return {
        archive: store.state.data.permissions.display_menu_archive_event,
        current: store.state.data.permissions.display_menu_current_event,
        forecast: store.state.data.permissions.display_menu_forecast,
        dashboard: store.state.data.permissions.display_menu_dashboard,
        nowcasting: store.state.data.permissions.display_menu_nowcasting,
        observations: store.state.data.permissions.display_menu_observations,
        messages: store.state.data.permissions.display_menu_messages,
        guide: store.state.data.permissions.display_menu_guide,
        guideApi: store.state.data.permissions.display_menu_guide && store.state.data.permissions.display_menu_guide_api,
        climateRecap: store.state.data.permissions.display_menu_climate_recap
      }
    },
    displayWBGTAndHeatIndex () {
      return store.state.data.permissions.display_data_wbgt
    },
    displayWBGTInHeader () {
      return store.state.data.permissions.display_component_wbgt
    },
    displayHeatIndexInHeader () {
      return store.state.data.permissions.display_component_heatindex
    },
    displayLightIntensity () {
      return store.state.data.permissions.display_data_luminosity
    },
    displayHeaderAverageTemperature () {
      return store.state.data.permissions.display_header_avgtemp
    },
    displayAirParifData () {
      return PLUGIN_AIRPARIF_ENABLED && store.state.data.permissions.display_airparif_data
    },
    displayHeaderRain () {
      return store.state.data.permissions.display_header_rain
    },
    displayHeaderAirParifData () {
      return store.computed.displayAirParifData()
    },
    displayEventDateSelector () {
      return store.state.data.permissions.display_menu_archive_event
    },
    displayNowcastingOptions () {
      let warningDelay = 20
      if (store.state.data.permissions.display_nowcasting_warning_after_short_period) {
        warningDelay = vrsStoreConfig.state.data.DISPLAY_NOWCASTING_WARNING_RADAR_SHORT_PERIOD
      } else if (store.state.data.permissions.display_nowcasting_warning_after_long_period) {
        warningDelay = vrsStoreConfig.state.data.DISPLAY_NOWCASTING_WARNING_RADAR_LONG_PERIOD
      }
      return {
        rain: store.state.data.permissions.display_nowcasting_rain,
        rainStatus: store.state.data.permissions.display_rain_status,
        stations: store.state.data.permissions.display_nowcasting_stations,
        info: store.state.data.permissions.display_nowcasting_info,
        warningDelay
      }
    },
    defaultHomePage () {
      if (store.state.data.permissions.display_menu_climate_recap) return '/event/active/climaterecap' // AppRoute.CLIMATERECAP
      if (store.state.data.permissions.display_menu_forecast) return '/event/active/forecast' // AppRoute.FORECAST
      if (store.state.data.permissions.display_menu_dashboard) return '/dashboard' // AppRoute.MEDIA_DASHBOARD
      if (store.state.data.permissions.display_menu_nowcasting) return '/event/active/nowcasting' // AppRoute.NOWCASTING
      if (store.state.data.permissions.display_menu_observations) return '/event/active/observations' // AppRoute.OBSERVATIONS
      if (store.state.data.permissions.display_menu_dashboard) return '/dashboard' // AppRoute.MEDIA_DASHBOARD
      return '/profile' // AppRoute.PROFILE
    },
    canAccessToForecastClimate (): boolean {
      return store.state.data.permissions.display_menu_climate_recap && store.state.data.permissions.display_menu_forecast
    },
    canManageDrawing (): boolean {
      return store.state.data.permissions.display_drawn_images_control
    }
  },
  actions: {
    async login (username: string, password: string) {
      store.state.loading = true
      store.state.error = null
      try {
        const authResponse = await apiService.login(username, password)
        preferences.token = authResponse.data.token
        preferences.permissions = authResponse.data.permissions
        preferences.username = username
        store.state.data.isAuthenticated = true
        store.state.data.user = {
          username,
          dashboards: authResponse.data.dashboards?.filter(d => d) || []
        }
        store.state.data.permissions = authResponse.data.permissions
        Sentry.setUser({ username })
        crossbarService.restart()
        delayRefreshToken()
      } catch (error) {
        console.error(error)
        store.state.data.isAuthenticated = false
        store.state.error = new Error('Error during login. Please retry.')
      }
      store.state.loading = false
    },

    async update (data) {
      store.state.loading = true
      store.state.error = null
      try {
        const user = await apiService.updateUser(data)
        store.state.data.user = user.data
      } catch (error) {
        store.state.error = new Error('Error during update. Please retry.')
      }
      store.state.loading = false
    },

    async signUp (data: SignUpParameters) {
      store.state.loading = true
      store.state.error = null
      try {
        await apiService.signUp(data)
      } catch (error) {
        const defaultMessage = 'Error during signup. Please retry.'
        if (error.response) {
          store.state.error = {
            message: defaultMessage,
            response: error.response.data
          }
        } else {
          store.state.error = {
            message: defaultMessage
          }
        }
      }
      store.state.loading = false
    },
    async resetPassword (data: { password: string; token: string}) {
      store.state.loading = true
      store.state.error = null
      try {
        await apiService.resetPassword(data)
      } catch (error) {
        const message = 'Error during reset. Please try again'
        if (error.response) {
          store.state.error = { message, response: error.response.data }
        } else {
          store.state.error = { message }
        }
      }
      store.state.loading = false
    },
    async refreshToken () {
      store.state.loading = true
      store.state.error = null
      try {
        const refreshResponse = await apiService.refreshToken(preferences.token)
        preferences.token = refreshResponse.data.token
        preferences.permissions = refreshResponse.data.permissions
        store.state.data.isAuthenticated = true
        store.state.data.permissions = refreshResponse.data.permissions
        Sentry.setUser({ username: preferences.username })
        crossbarService.restart()
        delayRefreshToken()
      } catch (error) {
        console.error(error)
        preferences.token = null
        store.state.data.isAuthenticated = false
        window.location.href = '#/login'
        // store.state.error = new Error('Error during login. Please retry.')
      }
      store.state.loading = false
    },
    async logout () {
      store.state.loading = true
      store.state.error = null
      store.state.data.isAuthenticated = false
      store.state.data.user = null
      store.state.data.permissions = {} as UserPermissions
      Sentry.configureScope(scope => scope.setUser(null))

      try {
        await apiService.logout()
        crossbarService.close()
      } catch (error) {
        // store.state.error = new Error('Error during login. Please retry.')
      }
      preferences.token = null
      preferences.permissions = {} as UserPermissions
      preferences.username = null
      store.state.loading = false
    },
    async getUser () {
      if (!store.state.data.isAuthenticated) return
      store.state.loading = true
      store.state.error = null
      try {
        const user = await apiService.getUser()
        store.state.data.user = {
          ...user.data,
          dashboards: user.data.dashboards?.filter(d => d) || []
        }
      } catch (error) {
        store.state.error = new Error('Error during login. Please retry.')
      }
      store.state.loading = false
    },
    async saveDashboards (dashboards) {
      store.state.loading = true
      store.state.error = null
      try {
        const user = await apiService.patchUser({
          dashboards
        })
        store.state.data.user = user.data
      } catch (error) {
        store.state.error = new Error('Error during login. Please retry.')
      }
      store.state.loading = false
    }
  },
  watch: {
    /**
     * When user is authenticated,
     * we fetch all the events
     */
    'data.isAuthenticated': {
      immediate: true,
      handler (newValue) {
        if (newValue === true) {
          vrsStoreEvent.actions.fetchAllEvents()
        }
      }
    }
  }
}

export const vrsStoreAuth = createStore(store)
