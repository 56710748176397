














































export default {
  name: 'MultiSelect',
  props: ['options', 'selected', 'id'],
  data () {
    return {
      show: false,
      values: {}
    }
  },
  methods: {
    /**
     * Manage options, according to exclusive ones.
     *
     * If an option is exclusive and selected,
     * we need to set to false every other options.
     *
     * If a new option is checked,
     * we need to remove the previous exclusive ones.
     *
     * Then emit an event to the parent (and, in return,
     * get the new value)
     */
    updateValues (option, event) {
      const newValue: boolean = event.target.checked

      /**
       * If an option is exclusive and selected,
       * we reset values property.
       */
      if (this.options[option].exclusive && newValue) {
        this.values = {
          [option]: true
        }
        this.$emit('update', [option])
        return
      }

      /**
       * If a non exclusive option is checked,
       * remove all previous exclusive ones.
       */
      Object.keys(this.values)
        .filter(currentOption => this.options[currentOption].exclusive)
        .forEach(currentOption => delete this.values[currentOption])

      this.values[option] = newValue

      const values = Object.entries(this.values)
        .filter(([, value]) => value)
        .map(([key]) => key)

      this.$emit('update', values)
    }
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler (selected: string[]) {
        for (const option of Object.keys(this.options)) {
          this.values[option] = selected.includes(option)
        }
      }
    }
  }
}
