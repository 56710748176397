


































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

import Loader from '@/components/ui/Loader/Loader.vue'
import UpdateTime from '@/components/ui/UpdateTime/UpdateTime.vue'
import Timeline from '@/components/ui/Timeline/Timeline.vue'
import Button from '@/components/ui/Button/Button.vue'

import ForecastMix from '@/components/data/forecast/ForecastMix.vue'
import RainInformation from '@/components/data/forecast/rain/RainInformation.vue'
import preferences from '@/services/preferences'

import GroundStationsMap from '@/components/data/weatherstation/GroundStationsMap.vue'

import { fetchMessages } from '@/store/message/store'
import { fetchWeatherStationDataLatest } from '@/store/weatherstation/store'
import { vrsStore, fetchForecastDataGlobal } from '@/store'

import { StateForecast } from '@/store/forecast/definitions'
import { StateApp } from '@/store/app/definitions'

import '@/styles/compiled-icons/'

@Component({
  components: {
    'ui-loader': Loader,
    'ui-button': Button,
    'ui-update-time': UpdateTime,
    'ui-timeline': Timeline,
    'forecast-mix': ForecastMix,
    'forecast-rain-information': RainInformation,
    GroundStationsMap
  }
})
export default class MediaDashboard extends Vue {
  storeForecast: StateForecast = vrsStore.state.forecast
  stateEvent = vrsStore.state.event
  stateMessage = vrsStore.state.message
  stateWeatherStation = vrsStore.state.weatherStation
  stateGeneral = vrsStore.state
  storeApp: StateApp = vrsStore.state.app
  storeConfig = vrsStore.state.config
  view: string = preferences.view

  @Prop({ type: String, default: 'forecast' }) readonly section: string | undefined

  get hourlyData () {
    return vrsStore.state.forecast.data.hourly?.data?.slice(0, 1)
  }

  get flattenData () {
    if (!this.hourlyData || !vrsStore.state.forecast.data.hourly?.flattenData) return []
    return vrsStore.state.forecast.data.hourly?.flattenData?.slice(0, this.hourlyData[0]?.data?.length || 0) || []
  }

  get displayForecast () {
    return this.section === 'forecast'
  }

  get displayRain () {
    return this.section === 'rain'
  }

  get displayStations () {
    return this.section === 'stations'
  }

  get displayMessages () {
    return this.section === 'messages'
  }

  async mounted () {
    vrsStore.state.app.data.eventId = 'active'
    await Vue.nextTick()
    await fetchForecastDataGlobal()
    await fetchMessages(vrsStore.state.app.data.eventId)
    await fetchWeatherStationDataLatest(this.stateEvent.data?.stations?.map(s => s.id.toString()))
  }
}
