var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"draggableModal",staticClass:"dialog group fixed rounded-t-md bg-background border border-solid border-gray z-1750 shadow",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('close')}}},[_c('header',{staticClass:"relative h-12 p-2 flex items-center select-none cursor-grab"},[(!_vm.minimized)?_vm._t("header",function(){return [_c('h2',{staticClass:"pointer-events-none text-xl text-font"},[_vm._v(_vm._s(_vm.title))]),(_vm.closable)?_c('ui-button',{staticClass:"rounded-full icon-fa-close center w-8 h-8 absolute right-2 top-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}}):_vm._e()]}):_vm._t("header-minimized",function(){return [_c('h2',{staticClass:"pointer-events-none text-xl text-center text-font w-full"},[_vm._v(_vm._s(_vm.title))])]}),(_vm.minimizable)?_c('ui-button',{staticClass:"widget-button rounded-full shadow-lg center w-8 h-8 absolute top-dashboard-widget left-dashboard-widget ",class:{
        'group-hover:block': !_vm.minimized,
        'hidden': !_vm.minimized,
        'opacity-50': _vm.minimized,
        'group-hover:opacity-100': _vm.minimized,
      },on:{"click":function($event){return _vm.toggleMinimized()}}},[_c('span',{staticClass:"text-base",class:{
          'icon-fa-minus': !_vm.minimized,
          'icon-fa-enlarge1': _vm.minimized,
        }})]):_vm._e()],2),(!_vm.minimized)?[_c('main',{class:_vm.mainClass},[_vm._t("default",function(){return [_vm._v(" This is the main part of your Dialog ")]})],2),(_vm.$slots.footer)?_c('footer',{staticClass:"w-full flex justify-between mt-4 p-4"},[_vm._t("footer")],2):_vm._e(),(_vm.resizable)?_c('span',{ref:"resizeHandler",staticClass:"resizeHandler absolute bottom-0 right-0 w-4 h-4 cursor-resize"}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }