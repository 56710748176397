
// @ts-nocheck
import { DrawingData } from '@/store/drawing/definitions'
import L from 'leaflet'

/**
 * Initialize the drawing from a previous one.
 *
 * @param map
 * @param drawing
 */
function initDrawingGeomanFrom (map: L.Map, drawing: DrawingData) {
  const layers = []
  drawing.data.forEach(drawing => {
    if (drawing.type === 'circle') {
      layers.push(L.circle(drawing.latlng, {
        radius: drawing.radius,
        color: drawing.color,
        pmIgnore: false
      }))
    } else if (drawing.type === 'text') {
      const marker = L.marker(drawing.latlng, {
        textMarker: true,
        text: drawing.text,
        pmIgnore: false,
        class: ''
      })
      marker.pm.getElement().style['text-shadow'] = '0 0 1em black'
      marker.pm.getElement().style.color = drawing.color
      marker.pm.getElement().style['background-color'] = 'transparent' // options.color + '25'
      marker.pm.getElement().style['font-size'] = drawing.size
      layers.push(marker)
    } else if (drawing.type === 'Feature') {
      layers.push(L.geoJSON(
        drawing,
        {
          ...drawing.properties,
          pmIgnore: false
        } as L.GeoJSONOptions
      ))
    }
  })

  new L.LayerGroup(layers).addTo(map)
  map.pm.Toolbar.setButtonDisabled('Save', false)
}

export function enableGeoman (
  map: L.Map,
  onDrawingEnd: (geojsons: any) => void,
  onDrawingCancel: (geojsons: any) => void,
  drawingMode: 'new'|'edit'|'copy',
  currentDrawing: any
) {
  map.pm.initialize(map)

  map.on('pm:create', (e) => {
    e.layer.options.pmIgnore = false
    map.pm.Toolbar.setButtonDisabled('Save', false)

    L.PM.reInitLayer(e.layer)
    // https://github.com/geoman-io/leaflet-geoman/issues/1465
    if (e.shape === 'Text') {
      e.layer.pm.enable()
      e.layer.pm.focus()
      const options = map.pm.getGlobalOptions().pathOptions
      if (options) {
        e.layer.pm.getElement().style['text-shadow'] = '0 0 1em black'
        e.layer.pm.getElement().style.color = options.color
        e.layer.pm.getElement().style['background-color'] = 'transparent' // options.color + '25'
        e.layer.pm.getElement().style['font-size'] = options.fontSize + 'px'
      }
    }
  })
  map.on('pm:remove', () => {
    if (map.pm.getGeomanLayers().length === 0) {
      map.pm.Toolbar.setButtonDisabled('Save', true)
    }
  })

  map.pm.addControls({
    position: 'topright',
    drawCircleMarker: false,
    drawMarker: false,
    rotateMode: false,
    cutPolygon: false,
    drawText: false
  })

  // Set default color as blue
  map.pm.setGlobalOptions({ pathOptions: { color: '#0000FF' } })
  const colorControl = map.pm.Toolbar.createCustomControl({
    name: 'Color',
    block: 'edit',
    title: 'Change current color',
    className: 'control-icon leaflet-pm-icon-color',
    actions: [
      {
        text: 'Cancel',
        onClick: () => {
          colorControl.toggle()
          map.pm.getGeomanLayers().forEach(layer => {
            layer.removeEventListener('click')
          })
        }
      },
      ...['#000000', '#FF0000', '#0000FF', '#FFFF00', '#00FF00'].map(color => {
        return {
          text: `<p class="leaflet-pm-color-option${color === '#0000FF' ? ' active' : ''}" style="background: ${color}">·</p>`,
          onClick: (e) => {
            map.pm.setGlobalOptions({ pathOptions: { color } })
            document.querySelectorAll('.leaflet-pm-color-option').forEach((el) => { el.classList.remove('active') })
            e.target.classList.add('active')

            map.pm.getGeomanLayers().forEach(layer => {
              layer.addEventListener('click', () => {
                if (layer.pm.textArea) {
                  layer.pm.textArea.style.color = color
                } else {
                  layer._path.setAttribute('stroke', color)
                  layer._path.setAttribute('fill', color)
                }
              })
            })
          }
        }
      })
    ]
  })

  const textControl = map.pm.Toolbar.createCustomControl({
    name: 'addText',
    title: 'Draw text',
    block: 'draw',
    jsClass: 'Text',
    className: 'control-icon leaflet-pm-icon-text',
    actions: [
      {
        text: 'Cancel',
        onClick: () => {
          map.pm.getGeomanLayers().forEach(layer => {
            layer.removeEventListener('click')
          })
          textControl.toggle()
          map.pm.Draw.Text.toggle()
        }
      },
      ...[12, 16, 24, 32, 48, 64].map(fontSize => {
        return {
          text: `<p class="leaflet-pm-weight-option">${fontSize}px</p>`,
          onClick: () => {
            map.pm.setGlobalOptions({ pathOptions: { fontSize } })
            map.pm.Draw.Text.toggle()
            textControl.toggle()

            map.pm.getGeomanLayers().forEach(layer => {
              layer.addEventListener('click', () => {
                if (layer.pm.textArea) {
                  layer.pm.textArea.style['font-size'] = fontSize + 'px'
                }
              })
            })
          }
        }
      })
    ],
    doToggle: true,
    toggleStatus: false,
    disableOtherButtons: true
  })

  const saveControl = map.pm.Toolbar.createCustomControl({
    name: 'Save',
    block: 'edit',
    title: 'Save drawing',
    className: 'control-icon icon-fa-save',
    disabled: true,
    afterClick: () => {
      const drawings = []
      map.pm.getGeomanLayers().forEach(layer => {
        if (layer._mRadius) {
          // If radius : circle
          drawings.push({
            type: 'circle',
            latlng: layer._latlng,
            radius: layer._mRadius,
            color: layer._path.getAttribute('fill')
          })
        } else if (layer.options.text) {
          // If options.text : text
          drawings.push({
            type: 'text',
            text: layer.options.text,
            latlng: layer._latlng,
            size: layer.pm.textArea.style['font-size'],
            color: layer.pm.textArea.style.color
          })
        } else {
          // else geojson
          const geojson = layer.toGeoJSON()
          drawings.push({
            ...geojson,
            properties: {
              ...layer.options,
              style: { color: layer._path.getAttribute('fill') }
            }
          })
        }
      })

      onDrawingEnd(drawings)
      saveControl.toggle()
    }
  })

  const cancelControl = map.pm.Toolbar.createCustomControl({
    name: 'Cancel',
    block: 'edit',
    title: 'Cancel drawing',
    className: 'control-icon icon-fa-exit',
    afterClick: () => {
      const geojsons = []
      map.pm.getGeomanLayers().forEach(layer => {
        geojsons.push({
          ...layer.toGeoJSON(),
          properties: layer.options
        })
      })

      onDrawingCancel(geojsons)
      cancelControl.toggle()
    }
  })

  if (['edit', 'copy'].includes(drawingMode) && currentDrawing) {
    initDrawingGeomanFrom(map, currentDrawing)
  }
}

export function disableGeoman (map: L.Map) {
  map.pm.getGeomanLayers().forEach(layer => {
    layer.removeFrom(map)
  })

  map.pm.removeControls()
}
